<template>
  <el-dialog
    :title="modalType === 'edit' ? '修改违约规则' : '添加违约规则'"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="defaultWidth"
    @close="closeModal"
  >
    <el-form
      :ref="formName"
      :model="modalData"
      :rules="ruleValidate"
      label-width="110px"
    >
      <el-form-item label="违约原因：" prop="rule">
        <el-input
          :disabled="modalType === 'edit'"
          v-model="modalData.rule"
          placeholder="请输入违约原因"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="违约金额：" prop="money">
        <el-input
          v-model="modalData.money"
          placeholder="请输入套餐金额"
          :maxLength="50"
          double
        ></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="closeModal"> 取消 </el-button>
      <el-button @click="submit" type="primary"> 确定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
export default {
  mixins: [modalMixin],
  props: ["modalData", "modalType"],
  emits: ["submit"],
  data() {
    return {
      isShowNum: false,
      formName: "form",
      ruleValidate: {},
    };
  },
  methods: {
    // 修改使用次数
    changeNum() {
      this.isShowNum = !!this.modalData;
    },
    submit() {
      this.validateForm().then((res) => {
        let obj = this.deepClone(this.modalData);
        obj.money = obj.money * 100;
        this.$emit("submit", obj);
      });
    },
  },
};
</script>
